import { API_BASE_URL, ACCESS_TOKEN } from '../common';
import axios from 'axios'

// Add a request interceptor
axios.interceptors.request.use(
    config => {
         
        const token = localStorage.getItem("ACCESS_TOKEN")
        const headers = {
            authorization: 'Bearer ' + localStorage.getItem("ACCESS_TOKEN"),
            'Content-Type': 'application/json',
        }
        if (token) {
            config.headers = headers;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

const instance = axios.create();

export const request = (options) => {
    return axios(options.url, options
    ).then(function (response) {
         
        console.log(response);
        return response;
    })


};
export const NoAuthRequest = (options) => {
    return instance(options.url, options
    ).then(function (response) {
         
        console.log(response);
        return response;
    })


}


